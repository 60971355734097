import React from "react";
import Layout from "../../components/layout";
import CapabilitiesHeroSection from "../../components/capabilitiesherosection";
import AuditButtons from "../../components/auditbuttons";
import SingleButton from "../../components/singlebutton";
import { Helmet } from "react-helmet";
import Audits from "../../components/audits";
import Arrow from "../../components/arrow";

import capabilityicon from "../../images/capability-creative-content-and-production-2.png";

import contentstrategy from "../../images/digital-media-planning.png";
import socialmediaads from "../../images/social-media-ads.png";
import digitaldisplaybanners from "../../images/content-creation.png";
import emailtemplatedesign from "../../images/email-template-design.png";
import animation from "../../images/animation.png";
import videoproduction from "../../images/video-production.png";
import audio from "../../images/audio.png";
import printcreative from "../../images/print-creative.png";


import rowtwoheroleft from "../../images/creative-content-production-row-two-hero-left.jpg";
import rowtwoheroright from "../../images/creative-content-production-row-two-hero-right.jpg";

import rowsixhero1 from "../../images/creative-content-production-row-six-hero-1.png";
import rowsixhero2 from "../../images/creative-content-production-row-six-hero-2.png";
import rowsixhero3 from "../../images/creative-content-production-row-six-hero-3.png";
import yellowarrow from "../../images/yellow-arrow.png";

import rowelevenhero from "../../images/creative-content-production-row-eleven-hero.jpg";

import "../../scss/capabilitypage.scss";

const CreativeContentProduction = ({ data }) => {
const metadescription = "Next&Co's creative and content production agency services provide data driven content that is surprisingly personal and  accountable to performance visit our site or call 1300 191 950";
const metatitle = "Creative Content Agency Melbourne - Next&Co";
const metaurl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription}/>
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <Arrow/>
      <div className="capability-page-general creative-content-production-page">
        <Layout>
          <CapabilitiesHeroSection
            heading="Creative &amp; Content Production"
            description="Creative &amp; Content – Data-driven content (that is surprisingly personal)"
            icon={capabilityicon}
            imagedesktop={data.desktop.childImageSharp.fluid}
            imagemobile={data.mobile.childImageSharp.fluid}
          />
          <section id="row-three">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container row">
                  <div className="col-one">
                    <h4 className="col-onef-heading">What we do</h4>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={contentstrategy}/>
                        </span>
                        <div className="content">
                          <h3>Content strategy</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={socialmediaads} />
                        </span>
                        <div className="content">
                          <h3>Social media ads</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={digitaldisplaybanners} />
                        </span>
                        <div className="content">
                          <h3>Digital display banners</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={emailtemplatedesign} />
                        </span>
                        <div className="content">
                          <h3>Email template design</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={animation} />
                        </span>
                        <div className="content">
                          <h3>Animation</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={videoproduction} />
                        </span>
                        <div className="content">
                          <h3>Video production</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={audio} />
                        </span>
                        <div className="content">
                          <h3>Audio</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={printcreative} />
                        </span>
                        <div className="content">
                          <h3>Print creative</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AuditButtons
            title1="Let’s talk about your project."
            linktitle1="Get in touch"
            link1="#"
          />
          <section id="row-two">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <h4>Creative that is measured for its emotional and brand impact &amp; allows us to tell your story.</h4>
                </div>
                <div className="row">
                  <div className="col">
                    <img alt="" src={rowtwoheroleft} />
                  </div>
                  <div className="col">
                    <img alt="" src={rowtwoheroright} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-six">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <h4>Our framework</h4>
                  <div className="row">
                    <div className="col full">
                      <p>Research <img alt="" src={yellowarrow} /> Production <img alt="" src={yellowarrow} /> Testing &amp; Optimisation</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img alt="" src={rowsixhero1} />
                    </div>
                    <div className="col">
                      <img alt="" src={rowsixhero2} />
                    </div>
                    <div className="col">
                      <img alt="" src={rowsixhero3} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-eleven">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <img alt="" src={rowelevenhero} />
                </div>
              </div>
            </div>
          </section>
          <section id="row-four">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h3>Many of Australia's leading businesses partner with us, you should too.</h3>
                    <SingleButton buttonText="Get in touch"/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Audits />
        </Layout>
      </div>
    </>
  );
};

export default CreativeContentProduction;

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "capabilities-desktop-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    mobile: file(relativePath: { eq: "capabilities-mobile-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
